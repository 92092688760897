// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

/*
 *
 * Rails - Stimulus
 * ---------------------------------------------------------------------------------
 *
 * 
 *
 */

import { Turbo, cable } from "@hotwired/turbo-rails"

// Uncomment the following to disable Turbo entirely 
// Turbo.session.drive = false

import "./controllers"

/*
 *
 * Rails - ActionText
 * ---------------------------------------------------------------------------------
 *
 * 
 *
 */


import "trix"
import "@rails/actiontext"

/*
 *
 * Bootstrap
 * ---------------------------------------------------------------------------------
 *
 * 
 *
 */
 
import * as bootstrap from "bootstrap"

/*
 *
 * Rails - Expose
 * ---------------------------------------------------------------------------------
 *
 * 
 *
 */

window.Rails = Rails;
